import { Skeleton } from '@candisio/design-system';
import { dataToPurchaseOrderCardDetails } from 'components/PurchaseOrders/PurchaseOrderDetailsCard/utils';
import {
  DocumentStatus,
  EcmDocumentStatus,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { isEmpty } from 'lodash';
import { ThreeWayMatchContext } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchContext';
import ThreeWayMatchWrapper from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchWrapper';
import useThreeWayMatch from 'views/DocumentDetails/components/ThreeWayMatch/useThreeWayMatch';
import { useGetThreeWayMatchData } from '../ThreeWayMatch/hooks/useGetThreeWayMatchData';
import { PurchaseOrderSection } from './PurchaseOrderSection';

interface PurchaseOrderSectionContainerProps {
  loading: boolean;
  documentId: string;
  documentAmount?: number | null;
  documentStatus?: DocumentStatus | EcmDocumentStatus;
  contactName?: string;
  accountsPayableNumber?: string | null;
}

export const PurchaseOrderSectionContainer = ({
  loading,
  documentAmount,
  documentStatus,
  documentId,
  contactName,
  accountsPayableNumber,
}: PurchaseOrderSectionContainerProps) => {
  const { isOnlyApprover } = useUserRoles();
  // TODO remove this and use useGetThreeWayMatchData, once all migration is done
  const {
    isLoading: threeWayMatchLoading,
    result,
    purchaseOrder,
  } = useThreeWayMatch(documentId);

  const { result: newResult, isLoading } = useGetThreeWayMatchData(documentId);

  const isAcceptedDocumentStatus = documentStatus
    ? [
        DocumentStatus.New,
        DocumentStatus.Open,
        DocumentStatus.Rejected,
      ].includes(documentStatus as any)
    : false;

  const isPurchaseOrderEditable = !isOnlyApprover && isAcceptedDocumentStatus;
  const hidePurchaseOrderSection =
    !isPurchaseOrderEditable && isEmpty(purchaseOrder);

  if (threeWayMatchLoading || loading) {
    return <Skeleton height="space80" width="100%" borderRadius="medium" />;
  }

  if (hidePurchaseOrderSection) return null;

  return (
    <ThreeWayMatchContext.Provider
      value={{ data: result.data, newData: newResult, isLoading }}>
      <ThreeWayMatchWrapper>
        <PurchaseOrderSection
          documentId={documentId}
          documentAmount={documentAmount}
          documentStatus={documentStatus}
          contactName={contactName}
          accountsPayableNumber={accountsPayableNumber}
          isPurchaseOrderEditable={isPurchaseOrderEditable}
          purchaseOrder={dataToPurchaseOrderCardDetails(purchaseOrder)}
        />
      </ThreeWayMatchWrapper>
    </ThreeWayMatchContext.Provider>
  );
};
