import { IconProps, useTheme } from '@candisio/design-system';
import { MatchState } from '../types';
/**
 * get three-way-match state styles
 */
export const useGetMatchStateStyles = () => {
  const { colors } = useTheme();

  const cellBgColor = {
    success: colors.greenbg,
    warning: colors.yellowbg,
    default: colors.gray0,
  };

  const cellTextColor = {
    success: colors.green700,
    warning: colors.yellow700,
    default: colors.gray700,
  };

  const leftSectionColor = {
    success: colors.green100,
    warning: colors.yellowbg,
    default: colors.gray0,
  };

  const iconProps: Record<string, IconProps> = {
    success: { icon: 'checkCircle', color: colors.green700 },
    warning: { icon: 'warning', color: colors.yellow700 },
    default: { icon: 'close', color: colors.gray700 },
  };

  const getMatchStyle = (matchState: MatchState) => {
    return {
      cellBgColor: cellBgColor[matchState],
      leftSectionColor: leftSectionColor[matchState],
      iconProps: iconProps[matchState],
      cellTextColor: cellTextColor[matchState],
    };
  };

  return getMatchStyle;
};
