import {
  Box,
  Button,
  Grid,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
// eslint-disable-next-line no-restricted-imports
import { Mentions } from 'antd/es';
import { MentionProps } from 'antd/es/mentions';
import { UserWithAbsence } from 'containers/absence/UserWithAbsence';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { User } from '../../generated-types/graphql.types';
import { MentionsInput } from './styles';

export const COMMENT_TEST_ID = 'comment-field';
export const COMMENT_SUBMIT_BUTTON_TEST_ID = 'comment-submit-button';

const { Option } = Mentions;

export type Props = MentionProps & {
  onCreateComment: (v: any) => Promise<any>;
  loadingMemberships: boolean;
  memberships: Array<Pick<User, 'id' | 'roles' | 'name' | 'avatarUrl'>>;
  isSubmitting?: boolean;
  restrictMentions?: boolean;
};

// https://stackoverflow.com/a/48460773/4299313
const adjustHeight = () => {
  const ele = document.querySelector(
    "textarea[name='mention']"
  ) as HTMLTextAreaElement;

  if (ele) {
    ele.style.height = '';
    ele.style.height = ele.scrollHeight + 'px';
  }
};

export const Comment = ({
  onCreateComment,
  isSubmitting,
  memberships,
  loadingMemberships,
  restrictMentions,
}: Props) => {
  // TODO: check way to multiline textarea, as new ant component doesn't support such prop
  // const [height, setHeight] = useState(20);

  const { isDocumentRelationsAvailable } = useEcm();

  const [t] = useTranslation();
  const [mentionedUserIds, setMentionedUserIds] = useState<string[]>([]);
  const [userInput, setUserInput] = useState<string>('');

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  // TODO: GRM-108 remove filter when mentions are not restricted anymore
  const membershipsFiltered = restrictMentions ? [] : memberships;

  const notFoundContentLabel = t(
    restrictMentions ? 'inputs.notAllowed' : 'inputs.noUserFound'
  );

  const mentionsInputPlaceholder = t(
    restrictMentions
      ? 'comment.mentioningRestrictedPlaceholder'
      : 'comment.placeholder'
  );

  return (
    <Form
      onSubmit={({ mention }) => {
        return onCreateComment({
          mention,
          // if the teamMembersLoading takes too long for some reason
          // we can still submit the probably correct selected ids
          mentionedUserIds: membershipsFiltered
            .filter(
              u =>
                (mention || '')
                  .toLowerCase()
                  .indexOf(`@${u.name.toLowerCase()}`) > -1
            )
            .map(u => u.id),
        });
      }}>
      {({ handleSubmit, form, values }) => {
        const hasValue = (values.mention ?? '').length > 0;

        const handleFormSubmit = async () => {
          await handleSubmit();
          form.reset();
          adjustHeight();
        };

        const isMac = () => {
          return window.navigator.userAgent.includes('Mac');
        };

        const onKeyDown = async (e: React.KeyboardEvent) => {
          if ((isMac() ? e.metaKey : e.ctrlKey) && e.key === 'Enter') {
            e.preventDefault();
            await handleFormSubmit();
          }
        };

        return (
          <Box position="relative">
            <form
              onKeyDown={e => onKeyDown(e)}
              onSubmit={async e => {
                e.preventDefault();
                await handleFormSubmit();
              }}>
              <Field
                name="mention"
                render={field => (
                  <MentionsInput
                    disabled={loadingMemberships}
                    {...field.input}
                    data-show-document-relations={isDocumentRelationsAvailable}
                    placeholder={mentionsInputPlaceholder}
                    onChange={e => {
                      adjustHeight();
                      field.input.onChange(e);
                    }}
                    notFoundContent={<span>{notFoundContentLabel}</span>}
                    data-testid={COMMENT_TEST_ID}
                    onSearch={v => setUserInput(v)}
                    onSelect={({ value }) => {
                      const selectedUser = membershipsFiltered.find(
                        u =>
                          u.name.toLowerCase() ===
                          (value && value.toLowerCase())
                      );

                      if (selectedUser?.id) {
                        setMentionedUserIds([
                          ...mentionedUserIds,
                          selectedUser.id,
                        ]);
                      }
                    }}
                    placement="top">
                    {membershipsFiltered
                      .filter(
                        u =>
                          u.name
                            .toLowerCase()
                            .indexOf(userInput.toLowerCase()) > -1
                      )
                      .map(({ name, id, avatarUrl }) => (
                        <Option key={id} value={name}>
                          <UserWithAbsence
                            label={name}
                            value={id}
                            image={avatarUrl}
                          />
                        </Option>
                      ))}
                  </MentionsInput>
                )}
              />
              <Grid
                autoFlow="column"
                gap="space4"
                position="absolute"
                bottom="space4"
                right="space8">
                {hasValue && (
                  <Button
                    onClick={() => form.reset()}
                    icon="close"
                    size="small"
                    label={t('comment.clear')}
                    tabIndex={-1}
                    variant="tertiary"
                  />
                )}
                <Button
                  type="submit"
                  data-testid={COMMENT_SUBMIT_BUTTON_TEST_ID}
                  icon={isSubmitting ? 'sandclock' : 'send'}
                  disabled={isSubmitting || !hasValue}
                  size="small"
                  variant="tertiary"
                  {...triggerProps}
                  ref={triggerRef}
                />
                {isOpen && (
                  <Tooltip {...tooltipProps} ref={tooltipRef}>
                    {isSubmitting ? (
                      <Text color="gray800" fontSize="basic">
                        {t('comment.creation.submittingTitle')}
                      </Text>
                    ) : (
                      <Grid>
                        <Text color="gray800" fontSize="basic">
                          {t('comment.creation.title')}
                        </Text>
                        <Text color="gray500" fontSize="small">
                          {t(
                            isMac()
                              ? 'comment.creation.shortcutInfoMac'
                              : 'comment.creation.shortcutInfo'
                          )}
                        </Text>{' '}
                      </Grid>
                    )}
                  </Tooltip>
                )}
              </Grid>
            </form>
          </Box>
        );
      }}
    </Form>
  );
};
