import { createContext, useContext } from 'react';
import { ThreeWayMatchResultData } from 'utils/three-way-match';
import { ThreeWayMatchRowData } from './types';

// TODO: delete old data once all migrated
interface ThreeWayMatchContextType {
  data: ThreeWayMatchResultData;
  newData: ThreeWayMatchRowData[];
  isLoading?: boolean;
}

export const ThreeWayMatchContext = createContext<ThreeWayMatchContextType>({
  data: [],
  newData: [],
});

export const useThreeWayMatchContext = () => {
  return useContext(ThreeWayMatchContext);
};
